.navbar{
    /* border: solid 1px black */
}

.height{
    min-height: 100px;
}

.blog-card{
    width: 18rem
}